@import "~@himaxwell/multiverse/src/styles/variables";

html, body {
  height: 100% !important;
  background-color: $g-25;
}

div[data-options*="PublicRouter"] {
  height: auto;
  min-height: 100vh;
  background-color: $g-50;
}
